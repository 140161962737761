import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authToken: localStorage.getItem('ak') === null
          ? ''
          : localStorage.getItem('ak'),
    isEligible: localStorage.getItem('ie') === null
          ? false
          : localStorage.getItem('ie'),
    eligibleExpiration: localStorage.getItem('ex') === null
          ? -1
          : localStorage.getItem('ex'),
    isShowHomeAlert: false,
    homeAlertMessage: ''
  },
  mutations: {
    setState(state, payload) {
      var keys = Object.keys(payload);
      keys.forEach((key) => {
        state[key] = payload[key];
      });
    },
  },
  actions: {
    setEligible({commit}, payload){
      console.log(process.env.VUE_APP_BASE_TOKEN);
      console.log(payload);
      localStorage.setItem('ak', payload)
      localStorage.setItem('ie', payload === process.env.VUE_APP_BASE_TOKEN)
      localStorage.setItem('ex', new Date().getTime() + (1000 * 60 * 30))
      commit('setState', {
        authToken: payload,
        isEligible: payload === process.env.VUE_APP_BASE_TOKEN,
        eligibleExpiration: new Date().getTime() + (1000 * 60 * 30)
      })
    },
    setAlert({commit}, payload){
      commit('setState', {
        homeAlertMessage: payload ?? 'Token is invalid, not an Eligible User!',
        isShowHomeAlert: true
      })
    },
    destroyAuth({commit}){
      commit('setState', {
        authToken: '',
        eligibleExpiration: -1,
        isEligible: false,
      })
    }
  },
  modules: {},
});
