<template>
    <v-navigation-drawer
      height="100%"
      dark
      app
      v-model="drawer"
      :mini-variant.sync="mini"
      :value="value"
      absolute
      clipped
      class="px-0"
    >
      <v-list dense class="py-0">
        <div
          v-for="item in items"
          :key="item.title"
        >
          <v-list-group v-if="item.hasChildren" no-action color="white" :prepend-icon="item.icon">
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(childItem) in item.children"
              :key="childItem.title"
              :to="childItem.link"
              link
            >
              <v-list-item-title v-text="childItem.title"></v-list-item-title>

              <v-list-item-icon>
                <v-icon v-text="childItem.icon"></v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
          <v-list-item :to="item.link" link v-else>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <!-- <v-list-item
          v-for="item in items"
          :to="item.link"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>

    <!-- <v-bottom-navigation
      :value="value"
      color="primary"
      class="hidden-md-and-up"
      :ripple="false"
      app
      height="auto"
      grow
    >
      <v-btn
        v-for="item in mobileItems"
        :to="item.link"
        :key="item.title"
        class="px-5 py-10"
      >
        <span>{{ item.title }}</span>
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </v-bottom-navigation> -->
</template>
<script>
import { menu } from "../../consts/menu";
export default {
  name: "Navigation",
  props: {
    isMini: Boolean,
    isOpen: Boolean,
  },
  data() {
    return {
      // drawer: true,//this.$vuetify.breakpoint.width >= 960 ? true : false,
      items: [],
      //mini: true,
      value: "",
    };
  },
  computed: {
    mini: {
      get() {
        return this.isMini;
      },
      set(newValue) {
        this.$emit("change", newValue);
      },
    },
    drawer:{
      get() {
        return this.isOpen;
      },
      set(newValue) {
        this.$emit("open", newValue);
      },
    }
  },
  created() {
    this.items = menu;
  },
  methods: {},
};
</script>
<style scoped>
.theme--light.v-btn.v-btn--has-bg {
  background-color: transparent;
}
</style>
